import { put, takeEvery, call } from 'redux-saga/effects';
import { setPlayerRequest, setPlayerSuccess, setPlayerFail } from '../slices/player';

import config from './../../config.js';


function* setPlayerRequestSaga({ payload }) {
	try {
		yield put(setPlayerSuccess(payload));
	}
	catch (error) {
		yield put(setPlayerFail(error));
	}
}


export default function* PlayerSaga() {
	yield takeEvery(setPlayerRequest, setPlayerRequestSaga);
}
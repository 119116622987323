import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	// audioUrl: '',
    // audioDuration: 0,
    // isPlaying: false,
    // currentTime: 0,
	error: null,
    status: 'idle'
};


const playersSlice = createSlice({
	name: 'player',
	initialState,
	reducers: {
		setPlayerRequest(state, action) {
			state.status = 'loading';
		},
		setPlayerSuccess(state, action) {
			// console.log(state.data)
			state.data[1] = state.data.length>0 && {...state.data[0], 'isComparing':true, 'isPlaying': false };
			state.data[0] = {...action.payload, 'isPlaying':true};
			state.status = 'completed';
		},
		setPlayerFail(state, action) {
			state.error = action.payload;
			state.status = 'idle';
		},
	},
});


export const { setPlayerRequest, setPlayerSuccess, setPlayerFail } = playersSlice.actions;
export default playersSlice.reducer;

import React from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';



const Loading = ({ color }) => {
	return (
		<div>
			<Spinner color={color} />
		</div>
	);
};

Loading.propTypes = {
	color: PropTypes.string
}
export default Loading;

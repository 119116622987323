
import React from 'react';

import './style.css';



const Footer = () => {

	return (
        <footer className="footer fixed-bottom d-none d-md-flex">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-lg-9">
                        <p className="m-1 text-light align-middle">
                            WikiStrings is a tool made by Fabulous Instruments © 2023 | PIVA 05179660872
                            <br />
                            <a href="https://www.wikistrings.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy</a>&nbsp;
                            |&nbsp;<a href="https://www.wikistrings.com/terms-of-service/" target="_blank" rel="noreferrer">Terms of Service</a>&nbsp;
                    		|&nbsp;<span>Developed by <a href="https://www.rebelcodes.com">Rebel Codes</a></span>
                        </p>
                    </div>
                    <div className="col-xs-12 col-lg-3">
                        <p className="m-1 text-light footer-socials">
                            <a href="https://www.instagram.com/start2impact/?hl=it">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://it-it.facebook.com/start2impact/">
                                <i className="fab fa-facebook"></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UC3pEI-6M5tlZe1sSrkERSww">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
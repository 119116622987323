import { all } from 'redux-saga/effects';

import stringsSaga from './sagas/strings';
import playerSaga from './sagas/player';


export default function* rootSaga() {
	yield all([
		stringsSaga(),
		playerSaga()
	]);
}

import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';




export default function LayoutWithoutMenu({component: Component, ...rest}) { 
  return (
    <Route {...rest} render={ otherProps => (
      <Component {...otherProps} />
    )} />
  );

}
const audio_strings = [
    {
        id: 1,
        audio_src: "/audios/cleartone.mp3",
        pic_url: "./images/strings/cleartone.jpg",
        title: "Cleartone",
        model: "Cl 123",
        amazon_url: 'https://www.amazon.it/Cleartone-Monster-chitarra-elettrica-confezioni/dp/B00BBN05AA/ref=sr_1_1?crid=2CCXW6CWX5FG1&keywords=cleartone&qid=1670087208&sprefix=cleartone%2Caps%2C116&sr=8-1'
    },
    {
        id: 2,
        audio_src: "/audios/john_pearse.mp3",
        pic_url: "./images/strings/john-pearse.jpg",
        title: "John Pearse",
        model: "JP 321",
        amazon_url: 'https://www.amazon.it/John-Pearse-170-Custom-Set/dp/B07PNPJFWG/ref=sr_1_2?keywords=John+Pearse&qid=1670087245&sr=8-2'
    },
    {
        id: 3,
        audio_src: "/audios/elixir.mp3",
        pic_url: "./images/strings/elixir.gif",
        title: "Elixir",
        model: "El 342",
        amazon_url: 'https://www.amazon.it/John-Pearse-170-Custom-Set/dp/B07PNPJFWG/ref=sr_1_2?keywords=John+Pearse&qid=1670087245&sr=8-2'
    },
    {
        id: 4,
        audio_src: "/audios/martin_rosse.mp3",
        pic_url: "./images/strings/martin-rosse.gif",
        title: "Martin Ros",
        model: "MR 178",
        amazon_url: 'https://www.amazon.it/John-Pearse-170-Custom-Set/dp/B07PNPJFWG/ref=sr_1_2?keywords=John+Pearse&qid=1670087245&sr=8-2'
    },
    {
        id: 5,
        audio_src: "/audios/soundstation.mp3",
        pic_url: "./images/strings/soundstation.jpg",
        title: "Soundsati",
        model: "St 177",
        amazon_url: 'https://www.amazon.it/John-Pearse-170-Custom-Set/dp/B07PNPJFWG/ref=sr_1_2?keywords=John+Pearse&qid=1670087245&sr=8-2'
    }
];

export default audio_strings;
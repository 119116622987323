import { combineReducers } from 'redux';

import {stringsSlice, playerSlice} from './slices';



const rootReducer = combineReducers({
	stringsSlice,
	playerSlice
});

export default rootReducer;

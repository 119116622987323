const AudioControls = ({
    string1:{title:title1, audio_src:string1_audio_src}, // string playing
    string2:{title:title2, audio_src:string2_audio_src}, // string to compare
    sourceId1="audio-element-id-1", sourceId2="audio-element-id-2",
    audioRef1, audioRef2, canvasRef,
    updateTimeTrack
}) => {
    // console.log('AudioControls -  now playing: '+title1,string1_audio_src);
    // console.log('AudioControls -  now comparing: '+title2,string2_audio_src);
    return (
        <div className="row">

            {/* Audio controls */}
            <div className="col-12">
                <canvas ref={canvasRef} width="380" height="100" />
                <audio
                    id={sourceId1}
                    ref={audioRef1}
                    src={string1_audio_src}
                    preload="auto"
                    onTimeUpdate={(e) => {
                        if( e.target.currentTime > 1) {
                            console.log('AudioControls - onTimeUpdate: '+e.target.currentTime);
                            updateTimeTrack(e.target.currentTime)
                        }
                    }}
                    // onEnded={() => onEnded}
                    className="w-100"
                    controls controlsList="nodownload" loop autoPlay
                >
                    Your browser does not support the audio element.
                </audio>
                <audio
                    id={sourceId2}
                    ref={audioRef2}
                    src={string2_audio_src}
                    preload="auto"
                    controls
                    style={{ display: 'none' }}
                >
                    Your browser does not support the audio element.
                </audio>
            </div>

        </div>
    );
}

export default AudioControls;
import React from 'react';

import { Helmet } from "react-helmet";

import './style.css';


const Error404 = () => {

    return (
        <>
            <Helmet>
                <title>Pagina non trovata | start2impact</title>
            </Helmet>
            <section className="height-full-screen d-flex justify-content-center align-items-center background-black text-white p-5">
                <div className="d-flex flex-column error-font">
                    {/* <img src="/home/images/404.png" alt="Pagina non trovata" /> */}
                    <h4 className="text-center mt-4">Ops!</h4>
                    <h6 className="text-center">Questa pagina non è momentaneamente disponibile.<br />Scrivici in chat cosa stavi cercando e ti aiuteremo.</h6>
                    <div className="d-flex justify-content-center mt-3 my-auto">
                        <a href="/home" role="button" className="btn btn-error">Torna alla Home</a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Error404;
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Helmet } from "react-helmet";

import {
    stringsFetchRequest,
    setPlayerRequest,
} from '../../store/slices/';


import Loading from '../../components/CardLoading';

import CardString from '../../components/CardString';
import AudioControls from '../../components/CardAudioPlayer/AudioControls';
import CardSong from '../../components/CardAudioPlayer/CardSong';
import Genres from '../../components/CardAudioPlayer/Genres';

import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

import './style.css';
import { set } from 'react-ga';


const sourceId1 = "audio-element-id1";
const sourceId2 = "audio-element-id2";
const colorAudio1Bars = 'rgb(25,135,84)';
const colorAudio2Bars = 'rgb(193,222,75)';
const audio_genres = [
    { id: 1, title: "Low Freq" },
    { id: 2, title: "Fingerstyle" },
    { id: 3, title: "Pick" },
    { id: 4, title: "Strum" },
    { id: 5, title: "Chord" },
];


const fade = (audioContext, gainNode, startValue, endValue, duration) => {
    if (gainNode) {
        gainNode.gain.setValueAtTime(startValue, audioContext.currentTime);
        gainNode.gain.linearRampToValueAtTime(endValue, audioContext.currentTime + duration);
    }
};


const drawSpectrogram = (analyser1=null, analyser2=null, canvasRef=null) => {

    const canvasContext = canvasRef.getContext('2d');

    console.log('drawSpectrogram');
    console.log('drawSpectrogram::analyser1',analyser1);
    console.log('drawSpectrogram::analyser2',analyser2);

    const bufferLength1 = analyser1 ? analyser1.frequencyBinCount : 0;
    const dataArray1 = new Uint8Array(bufferLength1);

    const bufferLength2 = analyser2 ? analyser2.frequencyBinCount : 0;
    const dataArray2 = new Uint8Array(bufferLength2);
  
    const draw = () => {
        requestAnimationFrame(draw);

        analyser1 && analyser1.getByteFrequencyData(dataArray1);
        analyser2 && analyser2.getByteFrequencyData(dataArray2);

        // canvasContext.fillStyle = 'rgb(0, 0, 0)';
        // canvasContext.fillRect(0, 0, canvas.width, canvas.height);
        canvasContext.fillStyle = "rgb(255,255,255,0.2)"; // Clears canvas before rendering bars (black with opacity 0.2)
        canvasContext.fillRect(0, 0, canvasRef.width, canvasRef.height);

        const barWidth = (canvasRef.width / bufferLength1) * 4;
        let barHeight;
        let x = 0;

        for (let i = 0; i < bufferLength1; i++) {
            // Draw track 1 bars
            barHeight = dataArray1[i] / 2;
            canvasContext.fillStyle = colorAudio1Bars;
            canvasContext.fillRect(x, canvasRef.height - barHeight/2, barWidth, barHeight);

            // Draw track 2 bars
            if (analyser2) {
                barHeight = dataArray2[i] / 2;
                canvasContext.fillStyle = colorAudio2Bars;
                canvasContext.fillRect(x + barWidth, canvasRef.height - barHeight/2, barWidth, barHeight);
            }

            x += barWidth * 2 + 1;
        }
    };
  
    canvasContext && draw();
};


const setAudioSource = (audioContext=null, audioRef=null, gainValue=1.0, currentTime=0) => {
    let analyser = null;
    console.log('useAudioSource');
    console.log('useAudioSource::audioContext',audioContext);
    console.log('useAudioSource::audioRef',audioRef);
    console.log('useAudioSource::currentTime',currentTime);
    if ( audioContext && audioRef?.current && audioRef?.current?.src && audioRef?.current?.src !== "" ) {
        if (!audioRef.current.sourceNode) {
            analyser = audioContext.createAnalyser();

            const sourceNode = audioContext.createMediaElementSource(audioRef.current);
            const gainNode = audioContext.createGain();
            gainNode.gain.value = gainValue;

            sourceNode.connect(analyser);
            sourceNode.connect(gainNode);
            gainNode.connect(audioContext.destination);
            
            audioRef.current.sourceNode = sourceNode;
            audioRef.current.analyser = analyser;
            audioRef.current.gainNode = gainNode;

            audioRef.current.play();
        }
        else {
            analyser = audioRef.current.analyser;
        }
        audioRef.current.currentTime = currentTime;
    }
    console.log('useAudioSource::analyser',analyser);
    return analyser;
};


const HomeGuest = ({ userSession }) => {

	const dispatch = useDispatch();

    const [audioContext] = useState(new (window.AudioContext || window.webkitAudioContext)());
    audioContext.resume();
    const audioRef1 = useRef(null);
    const audioRef2 = useRef(null);
    const canvasRef = useRef(null);

    let analyser1 = useRef(null);
    let analyser2 = useRef(null);

    // const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);

    const {data:strings, status:stringsStatus} = useSelector(state => state.stringsSlice);
    const {data:playerStrings, status:playerStatus} = useSelector(state => state.playerSlice);

    const syncAudio = () => {
        console.log("syncAudio");
        if( playerStrings?.[1] && audioRef2.current ) {
            // audioRef2.current.currentTime = audioRef1.current.currentTime = currentTime;
            // console.log("syncAudio to", currentTime);
            if (audioRef1.current.paused) {
                console.log("Audio 2 PAUSE");
                fade(audioContext, audioRef1.current.gainNode, 1, 0, 1); // fade out over 1 second
                audioRef2.current.pause();
            }
            else {
                console.log("Audio 2 PLAY");
                audioRef2.current.play();
            }
        }
    };

    useEffect(() => {
        console.log('useEffect');
        if (audioRef1.current) {
            console.log('currentTime 1',currentTime);
            analyser1.current = setAudioSource(audioContext, audioRef1, 1.0, currentTime);
        }
        if (audioRef2.current) {
            console.log('currentTime 2',currentTime);
            analyser2.current = setAudioSource(audioContext, audioRef2, 0.0, currentTime);
        }
        console.log("Analyser 1", analyser1.current);
        console.log("Analyser 2", analyser2.current);
        console.log("Canvas", canvasRef.current);
        if( canvasRef.current && analyser1.current ) {
            drawSpectrogram(analyser1.current, analyser2.current, canvasRef.current);
        }
        if (audioRef1.current && playerStrings?.[1]) {
            audioRef1.current.addEventListener('play', syncAudio);
            audioRef1.current.addEventListener('pause', syncAudio);
            audioRef1.current.addEventListener('seeked', syncAudio);
            // audioRef1.current.addEventListener('timeupdate', syncAudio);
        }
        return () => {
            if (audioRef1.current) {
                audioRef1.current.removeEventListener('play', syncAudio);
                audioRef1.current.removeEventListener('pause', syncAudio);
                audioRef1.current.removeEventListener('seeked', syncAudio);
                // audioRef1.current.removeEventListener('timeupdate', syncAudio);
            }
        };
    }, [playerStrings, playerStatus, audioContext, audioRef1, audioRef2, canvasRef]);

    // fetch strings DB
    useEffect(() => {
        dispatch(stringsFetchRequest());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	if ( stringsStatus!=="completed" ) {
        return <Loading color='success' />
    }

	return (
		<>
			<Helmet>
				<title>Home | WikiStrings</title>
				<link rel="canonical" href={`https://www.wikistrings.com/`} />
			</Helmet>
            <Navbar />
			<div className='home-page container height-full-screen'>				
                <div className="row">
                    <div className='col-12'>
                        <div id="strings-list" className='mt-3 d-flex flex-wrap'>
                            {
                                strings.map( (string, i) => (
                                    <CardString
                                        key={"string-"+string.id}
                                        id={string.id}
                                        pic_url={string.pic_url}
                                        title={string.title}
                                        model={string.model}
                                        ctaLabel={playerStrings.length===0?'+ Play':'Compare'}
                                        isComparing={playerStrings.length>0 && string.id===playerStrings.filter(string => string?.isComparing===true)[0]?.id}
                                        isPlaying={playerStrings.length>0 && string.id===playerStrings.filter(string => string?.isPlaying===true)[0]?.id}
                                        onCTAClick={()=> dispatch(setPlayerRequest(string))}
                                    />
                                ))
                            }
                        </div>

                        <section className="audio-player fixed-bottom bg-white">

                            {/* { (audio_genres.length>0 && isPlaying) && (
                                <Genres values={audio_genres} current={2} />
                            )} */}

                            <div className='row'>
                                { playerStrings[0] && (
                                    <div className='col-12 col-md-10'>
                                        <AudioControls
                                            string1={playerStrings[0]} // current playing string
                                            string2={playerStrings[1]} // string to compare
                                            sourceId1={sourceId1} sourceId2={sourceId2} // DOM id audio elements
                                            audioRef1={audioRef1} audioRef2={audioRef2} 
                                            canvasRef={canvasRef}
                                            updateTimeTrack={setCurrentTime}
                                        />
                                        <div className="row">
                                            { playerStrings[0] && <CardSong isPlaying={true} string={playerStrings[0]} />}
                                            { playerStrings[1] && <CardSong isPlaying={false} string={playerStrings[1]} />}
                                        </div>
                                    </div>
                                )}
                            </div>

                        </section>
                    </div>
				</div>
			</div>

            <Footer />

		</>
	);
};

export default HomeGuest;
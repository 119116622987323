import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	error: null,
    status: 'idle'
};


const stringsSlice = createSlice({
	name: 'strings',
	initialState,
	reducers: {
		stringsFetchRequest(state, action) {
			state.status = 'loading';
		},
		stringsFetchSuccess(state, action) {
			state.data = action.payload;
			state.status = 'completed';
		},
		stringsFetchFail(state, action) {
			state.error = action.payload;
			state.status = 'idle';
		},
	},
});


export const { stringsFetchRequest, stringsFetchSuccess, stringsFetchFail } = stringsSlice.actions;
export default stringsSlice.reducer;

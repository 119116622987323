import { put, takeEvery, call } from 'redux-saga/effects';
import { stringsFetchRequest, stringsFetchSuccess, stringsFetchFail } from '../slices/strings';

import audio_strings from '../../datas/strings';


function* fetchStringsSaga() {
	try {
		yield put(stringsFetchSuccess(audio_strings));
	} catch (error) {
		yield put(stringsFetchFail('There was an error'));
	}
}

export default function* stringsSaga() {
	yield takeEvery(stringsFetchRequest.toString(), fetchStringsSaga);
}

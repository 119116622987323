import './style.css';



const CardString = ({id, pic_url, title, model, ctaLabel, isPlaying, isComparing, onCTAClick}) => {

    const analytics = (title, model) => {
        // analytics(title, model)
        // console.log("User clicked on "+title+' - '+model);
        // return false;
    }

    return(
        <div className='col-4 p-2' onClick={onCTAClick}> 
            <div className={
                    `card${isPlaying?' border border-2 border-success':''}${isComparing?' border border-2 border-secondary':''}`}>
                <img className="card-img-top" src={pic_url} alt={title} width="80" height="80" />
                <div className="card-body">
                    {/* <span className="fs-6">{title}</span> */}
                    <span className="fw-semibold">{model}</span>
                    <div className="d-grid gap-2">
                        <button className={`btn btn-extrasmall ${isPlaying?'btn-success':isComparing?'btn-secondary':'btn-light'}`}>
                            {
                                isPlaying
                                    ? <div><i className="fa-solid fa-volume-high me-1"></i> <span className="badge badge-pill buy"><i className="fa-brands fa-amazon"></i> Get info</span></div>
                                    : isComparing ? <span><i className="fa-solid fa-magnifying-glass-chart me-1"></i></span> : ctaLabel
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CardString;
import './style.css';


const redirectAmazon = (amazonUrl="") => {
    console.log("Redirecting to: "+amazonUrl);
    if (amazonUrl!=="") {
        window.open(amazonUrl);
    }
    return;
}

const CardSong = ({isPlaying=false, string:{pic_url, title, model, amazon_url}}) => {
    return (
        <div className={isPlaying?'col-6 py-2 bg-success':'col-6 py-2 bg-secondary'}>
            <div className="row">
                <div className="col-7 pe-0">
                    <img className={isPlaying?'rounded mx-1':'rounded'} src={pic_url} alt={title} width="30" height="30" />
                    <span className={isPlaying?'badge text-bg-success':'badge text-bg-secondary'}>{model}</span>
                </div>
                <div className="col-5 ps-0">
                    <button className="btn btn-sm" type="button" onClick={()=>redirectAmazon(amazon_url)}> 
                        <div className="w-100 d-flex">
                            <span className="badge badge-pill buy"><i className="fa-brands fa-amazon"></i> Get info</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CardSong;
import React from 'react';
import { BrowserRouter, Switch } from "react-router-dom";

// import { AuthProvider } from "./context/authContext";
import LayoutWithoutMenu from './layouts/LayoutWithoutMenu.js';

import HomeGuest from './features/HomeGuest';
import Error404 from './features/404';

import './App.css';



export default function App() {
	return (
		<BrowserRouter>
			{/* <AuthProvider> */}
				<Switch>
					<LayoutWithoutMenu exact path={'/'} component={HomeGuest} />
					<LayoutWithoutMenu path='*' component={Error404} />
				</Switch>
			{/* </AuthProvider> */}
		</BrowserRouter>
	);
}
import { AiOutlineHome } from 'react-icons/ai';
import { HiOutlineAcademicCap } from 'react-icons/hi';


const config = {
    apiUrl: process.env.REACT_APP_API_URL,
    notificationsApiUrl: process.env.REACT_APP_NOTIFICATION_URL,
    authenticationApiUrl: process.env.REACT_APP_AUTHENTICATION_URL,
    menuItems: [
        { id: 1, name: 'Home',           to: '/home',            active:true,  isInternal:true, icon: AiOutlineHome },
        { id: 2, name: 'Master',         to: '/home/master',   active:false, isInternal:true, icon: HiOutlineAcademicCap },
    ],
    cookieName     : 'auth_token',
    loginPath      : '/users/sign_in',
    basepath       : '/home',
    onboardingPath : '/home/onboarding/master',
    daysShowWelcomeVideoAfterRegistration: 7,
    googleAnalyticsTrackerId: process.env.NODE_ENV === 'production' ? 'UA-xxxx-x' : null
}


export default config;